import Link from 'next/link';

export default function FourOhFour() {
    return (
        <div className="min-h-screen">
            <div className="flex !min-h-screen w-full flex-col items-center justify-center">
                <h1>404 - Page Not Found</h1>
                <Link href="/" className="text-blue hover:underline">
                    Go back home
                </Link>
            </div>
        </div>
    );
}
